import { Box, Grid, Typography } from "@mui/material";

export default function WhyMY() {
    const points = [
        <><strong>Single Sign-On (SSO): </strong> One login for all. Your identity everywhere with one click.</>,
        <><strong>User-Friendly Dashboard: </strong> Manage all your connected apps in one place.</>,
        <><strong>Secure and Verified: </strong> Scrutinized identity with a secure platform.</>,
        <><strong>APIs for Developers: </strong> Open and publically accessible APIs for creating your own apps.</>,
    ]
    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100%',
            // border: '1px solid black',
            // height: `calc(100vh - 47px)`,
            p: 3,
            pb: 5, pt: 3,
        }}
    // spacing={2}
    >
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                // mt: 2,
                // border: '1px solid black',
                bgcolor: 'primary.ultraLight',
                height: '100%',
                borderRadius: '4px',
                pt: 8,
                pb: 8,
                pr: {
                    xs: 2, md: 0
                }
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    // border: '1px solid black',
                    textAlign: 'left',
                    width: {
                        lg: '80%'
                    }
                }}
            >
                <Typography
                    variant="h3"
                    color="textPrimary"
                    align="center"
                    sx={{
                        // border: '1px solid black',
                        mb: {
                            xs: 3, md: 1
                        }
                    }}
                >
                    Why MazeYard?
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        maxWidth: '600px',
                        mt: 2,
                        mb: 2,
                        p: 2,
                        // border: '1px solid black',
                    }}
                    color="textSecondary"
                    align='center'
                >
                    With <strong>Service66</strong>,
                    our flagship identity management platform,
                    establish your identity throughout the industry.
                </Typography>
                <Box
                    sx={{
                        // border: '1px solid black',
                        display: 'inline-block'
                    }}
                >
                    <ul>
                        {
                            points.map((point, index) => {
                                return <li key={index}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            // border: '1px solid black',
                                            maxWidth: '700px',
                                        }}
                                        fullWidth={false}
                                    >
                                        {point}
                                    </Typography>
                                </li>
                            })
                        }
                    </ul>
                </Box>
            </Grid>
        </Grid>
    </Grid>
}