import React from 'react'
import { Grid, Typography } from '@mui/material'

export default function AppsHeader() {
    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
    >
        <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            gutterBottom
            sx={{
                marginTop: 4,
                marginBottom: 4,
            }}
        >
            Apps where you can login with Service66.
        </Typography>
    </Grid>
}