import { Grid, Link, Paper, Typography } from "@mui/material";

export default function GetInTouch() {
    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100%',
            // border: '1px solid black',
            // height: `calc(100vh - 47px)`,
            // p: 3,
            pb: 5, pt: 5,
            textAlign: 'center',
            bgcolor: 'primary.ultraLight'
        }}
    >
        <Grid
            container
            component={Paper}
            elevation={2}
            sx={{
                borderRadius: '20px',
                p: 5,
                width: {
                    xs: '95%', lg: '90%'
                },
                // border: '1px solid black',
            }}
        >
            <Grid
                item
                xs={12}
                lg={6}
                sx={{
                    // border: '1px solid black',
                    p: {
                        xs: 0, lg: 3
                    },
                }}
            >
                <Typography variant='h6'>
                    Have Questions?
                </Typography>
                <Typography variant='h5'>
                    Get in touch
                </Typography>
                <Typography variant='body1' sx={{ mt: 5 }}>
                    Fill out the form&nbsp;
                    <Link
                        href='/contact'
                        sx={{ color: 'primary.main', textTransform: 'none', textDecoration: 'none' }}
                    >
                        here
                    </Link>
                    <br />
                    and we will get back to you.
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                sx={{
                    // border: '1px solid black',
                    p: {
                        xs: 0, lg: 3
                    },
                    mt: {
                        xs: 3, lg: 0
                    }
                }}
            >
                <Typography
                    variant='body2'
                    color='textSecondary'
                    align='left'
                    sx={{
                    }}
                >
                    We are here to help and answer any questions you might have.
                    <br />
                    <br />
                    Reach out to us anytime!
                    We'd love to hear from you.
                    Whether you have feedback, a question, or just want to say hi,
                    feel free to get in touch.
                    Need support or have an inquiry?
                    Contact us, and we'll get back to you as soon as possible.
                    <br />
                    <br />
                    Let's work together to create an ecosystem
                    that will change the way <strong>India</strong> takes professional education.
                </Typography>
            </Grid>
        </Grid>
    </Grid>
}