import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import HomePage from './homePage';
import Topbar from './topbar';
import { Toolbar } from '@mui/material';
import Contact from './contact';
import Footer from './homePage/footer'
import Apps from './apps';
import Terms from './terms';
// import Service66 from './service66';

export default function AppRoutes() {
    const renderRoutes = () => {
        return <BrowserRouter>
            <Topbar />
            <Toolbar />
            <main>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/apps" element={<Apps />} />
                    <Route path="/terms" element={<Terms />} />
                    {/* <Route path="/service66" element={<Service66 />} />
                    <Route path="/projects" element={<Apps />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/terms" element={<Terms />} /> */}
                </Routes>
            </main>
            <Footer />
        </BrowserRouter>
    }

    return renderRoutes()
}