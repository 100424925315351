import React from 'react'
import { Box } from '@mui/material'
import AppsHeader from './appsHeader'
import AppsList from './appsList'

export default function Apps() {
    React.useEffect(() => {
        document.title = 'Mazeyard Apps'
    }, [])

    return <Box
        sx={{
            margin: 'auto',
        }}
    >
        <AppsHeader />
        <AppsList />
    </Box>
}