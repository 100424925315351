import React, { useState } from 'react'
import { TextField, Grid, Typography } from '@mui/material'
import SubmitButton from './submitButton'
import axios from 'axios'
import qs from 'qs'

export default function ContactForm(props) {
    const [submitBtnState, setSubmitBtnState] = useState(0)
    const [error, setError] = useState("")
    const [data, setData] = useState({
        email: "",
        query: "",
    })

    const changeData = (prop) => (event) => {
        setData({ ...data, [prop]: event.target.value })
        setSubmitBtnState(0)
        setError("")
    }

    const submitForm = () => {
        setSubmitBtnState(2)
        axios({
            method: 'POST',
            url: 'https://services.mazeyard.com/mazey.support.SupportService/contactUs/',
            data: qs.stringify({
                email: data.email,
                query: data.query,
            }),
            config: {
                headers: {
                },
                validateStatus: () => true
            }
        })
            .then(res => {
                setSubmitBtnState(1)
                setTimeout(() => props.setView("successBox"), 500)
            })
            .catch(err => {
                setError(err.response.data)
                setSubmitBtnState(-1)
            })
    }

    return <form noValidate autoComplete="off">
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                padding: 2,
                minHeight: 600,
            }}
        >
            <TextField
                required
                id="email-input"
                label="Your Email Address"
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                value={data.email}
                onChange={changeData('email')}
            />
            <TextField
                required
                id="query-input"
                label="Your Query"
                variant="outlined"
                multiline
                rows={15}
                sx={{
                    width: '100%',
                    maxWidth: 600,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                value={data.query}
                onChange={changeData('query')}
            />
            <Typography
                variant="caption"
                sx={{
                    color: 'error.main',
                }}
            >
                {error}
            </Typography>
            <SubmitButton
                submitBtnState={submitBtnState}
                setSubmitBtnState={setSubmitBtnState}
                submitForm={submitForm}
                data={data}
            />
        </Grid>
    </form>
}