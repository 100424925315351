import React from 'react'
import { Typography } from '@mui/material'


export default function SuggestionText() {

    return <div
        style={{
            paddingTop: 40,
            paddingBottom: 20,
        }}
    >
        <Typography variant="h5" align="center" sx={{ mb: 2, textDecoration: 'underline' }}>
            Contact Us
        </Typography>
        <Typography
            color="textSecondary"
            gutterBottom
            align="center"
        >
            If you are a registered user,
            <br />
            please use the help center in Service66 instead.
        </Typography>
    </div>
}