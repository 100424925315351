import React from 'react'
import HeroSection from './heroSection'
import Service66 from './service66'
import WhyMY from './whyMY'
import HowItWorks from './howItWorks'
import Developers from './developers'
import GetInTouch from './getInTouch'
// import Footer from '../footer'
// import MainSection from './mainSection'
// import Register from './register'
// import Develope from './develope'
// import Companion from './companion'
// import PublicServices from './publicServices'

export default function HomePage() {

    React.useEffect(() => {
        document.title = 'MazeYard - The Academic Companion'
    }, [])

    return <>
        <HeroSection />
        <Service66 />
        <HowItWorks />
        <WhyMY />
        <Developers />
        <GetInTouch />
        {/* <MainSection />
        <Companion />
        <Register />
        <PublicServices />
        <Develope />
        <Footer /> */}
    </>
}