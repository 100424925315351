import React from 'react'
import { Grid, Typography } from '@mui/material'

export default function SuccessBox() {

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            padding: 2,
            height: `calc(100vh - 60px)`,
            // border: '1px solid black'
        }}
    >
        <Typography
            color="textSecondary"
            align="center"
        >
            Thank you for reaching out to us.
            <br />
            We will get back to you soon.
        </Typography>
    </Grid>
}