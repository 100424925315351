import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            ultraLight: '#C3E3FD',
            light: '#6098F2',
            main: '#0A5BDD',
            dark: '#0A285E',
            ultraDark: '#08153A',
        },
        secondary: {
            main: '#F7BD0D',
        },
        background: {
            paper: '#FFFFFF',
            page: '#F5F9FD',
            dark: '#F5F5F5',
            darker: '#EBEBEB',
        },
        error: {
            ultraLight: '#FFC8C4',
            light: '#FF8A81',
            main: '#F44336',
        },
        success: {
            main: '#54B258',
        },
        text: {
            primary: '#0E1224',
            secondary: '##888688',
            disabled: '#CECECE',
            // primary: '#2C2C2C',
            // secondary: '#838383',
            // disabled: '#A3A3A3',
        },
        info: {
            main: '#2196F3',
        },
    },
});