import { Box, Button, Grid, Typography } from "@mui/material";
import devices from '../statics/Mazeyard_homepage_hero_image-removebg-preview.png'
import bg_blob from '../statics/hero_blob.svg'

export default function HeroSection() {
    const ctaButtons = <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
            mt: 2,
        }}
    >
        <Button
            variant="contained"
            sx={{
                bgcolor: 'primary.ultraDark',
                // change hover color to little more dark
                '&:hover': {
                    bgcolor: 'primary.dark'
                },
                textTransform: 'none',
                mr: 2
            }}
            color="primary"
            // redirect to a url
            onClick={() => window.location.href = 'https://service66.mazeyard.com'}
        >
            Get Started
        </Button>
        <Button
            variant="contained"
            sx={{
                bgcolor: 'primary.light',
                // change hover color to little more dark
                '&:hover': {
                    bgcolor: 'primary.ultraLight'
                },
                textTransform: 'none',
            }}
            // redirect to a url
            onClick={() => window.location.href = 'https://withmazeyard.com'}
        >
            Learn More
        </Button>
    </Grid>

    const header = <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            // border: '1px solid black',
            height: '100%',
            pb: {
                lg: '40px'
            },
        }}
    >
        <Box
            sx={{
                // border: '1px solid black',
                textAlign: 'left',
                width: {
                    xs: '90%', lg: '80%',
                }
            }}
        >
            <Typography variant="h3" sx={{ mb: '4px', pt: 5 }}>
                Academic Solutions
            </Typography>
            <Typography variant="h4" sx={{ mb: '3px' }}>
                For Your Academic Journey
            </Typography>
            <Typography variant="body1"
            // sx={{ lineHeight: '22px' }}
            >
                Elevate Your Professional Learning Experience.
                <br />
                Transform Your Journey with MazeYard and Witness the Change Firsthand.
                {/* more content here */}
            </Typography>
            {ctaButtons}
        </Box>
    </Grid>

    const image = <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            // border: '1px solid black',
            height: '100%',
            backgroundImage: `url(${bg_blob})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            pt: {
                xs: 7, lg: 0,
            },
            mt: {
                xs: 3, lg: 0
            }
        }}
    >
        <Box
            sx={{
                // border: '1px solid black',
                textAlign: 'left',
                width: {
                    lg: '90%'
                },
                p: 2,
            }}
        >
            <img
                src={devices}
                alt="devices"
                style={{
                    // border: '1px solid black',
                }}
                width="100%"
            />
        </Box>
    </Grid>

    return <Grid
        container
        sx={{
            width: '100%',
            // border: '1px solid black',
            height: `calc(100vh - 47px)`,
            // flexDirection: { xs: "column", md: "row" },
        }}
    >
        <Grid item xs={12} lg={6}>
            {header}
        </Grid>
        <Grid item xs={12} lg={6}>
            {image}
        </Grid>
    </Grid>
}