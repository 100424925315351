import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { DoneAll } from '@mui/icons-material'


export default function SubmitButton(props) {

    const submitButton = () => {
        if (props.submitBtnState === 2) {
            return <CircularProgress size={25} />
        } else if (props.submitBtnState === 0) {
            return "Submit"
        } else if (props.submitBtnState === 1) {
            return <DoneAll />
        } else {
            return "Error"
        }
    }

    return <Button
        variant="outlined"
        color="primary"
        disabled={props.submitBtnState !== 0 || props.data.email === "" || props.data.query === ""}
        sx={{
            marginTop: 2,
            marginBottom: 5,
        }}
        onClick={props.submitForm}
    >
        {submitButton()}
    </Button>
}