import { Box, Grid, Typography } from "@mui/material"
import idCard from '../statics/undraw_online_transactions_-02-ka.svg'

export default function Service66() {
    const contextTexts = [
        "MazeYard is a one stop solution for the academic needs of students and faculty designed to streamline the educational experience.",
        "Whether you're managing your coursework, scheduling classes, taking notes, or accessing essential resources, MazeYard simplifies these tasks, allowing you to focus on your studies.",
        "Additionally, our open APIs empower developers to create custom solutions tailored to academic needs. With robust integrations across numerous educational apps and services, our platform not only enhances security and convenience but also drives a seamless, interconnected academic ecosystem.",
        "Join us to transform the way you experience professional education and make the most of your academic journey."
    ]

    const context = <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            // mt: 2,
            // border: '1px solid black',
            bgcolor: 'primary.ultraLight',
            height: '100%',
            borderRadius: '4px',
            p: {
                xs: 3, lg: 0,
            }
        }}
    >
        <Box
            sx={{
                // border: '1px solid black',
                textAlign: 'center',
                width: {
                    lg: '80%'
                }
            }}
        >
            {
                contextTexts.map((eachText, index) => (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align='left'
                        key={index}
                        sx={{
                            mb: 2,
                            // color: 'divider'
                        }}
                    >
                        {eachText}
                    </Typography>
                ))
            }
        </Box>
    </Grid>

    const image = <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            // mt: 2,
            // border: '1px solid black',
            bgcolor: 'secondary.light',
            height: '100%',
            borderRadius: '4px',
            p: {
                xs: 3, lg: 0,
            }
        }}
    >
        <Box
            sx={{
                width: {
                    xs: '100%',
                    lg: '80%',
                    m: 0
                },
                // border: '1px solid black',
            }}
        >
            <img
                src={idCard}
                alt="id-card-image"
                style={{
                    width: '100%'
                }}
            />
        </Box>
        <Typography variant="h6" sx={{ m: 0, color: 'text.primary' }} align='center'>
            Unleash the full potential of your ID card.
        </Typography>
    </Grid>

    return <Grid
        container
        sx={{
            width: '100%',
            // border: '1px solid black',
            height: `calc(100vh - 47px)`,
            p: 3,
            mb: {
                xs: 20, lg: 0
            },
            mt: {
                xs: 15, lg: 0
            }
        }}
    // spacing={2}
    >
        <Grid item xs={12} lg={6}>
            {context}
        </Grid>
        <Grid item xs={12} lg={6}>
            {image}
        </Grid>
    </Grid>
}