import { AccountBox, Login, Verified } from "@mui/icons-material";
import { Grid, IconButton, Link, Paper, Typography } from "@mui/material";

export default function HowItWorks() {
    const iconSize = '45px'

    const items = [
        {
            image: <AccountBox sx={{ fontSize: iconSize, color: '#007bff' }} />,
            content: <>
                <Link href="https://service66.mazeyard.com/">
                    Register with Service66.
                </Link>
                <br />
                Create your account with the educational institute you are currently enrolled in.
            </>
        },
        {
            image: <Verified sx={{ fontSize: iconSize, color: '#28a745' }} />,
            content: <>
                <Link href="https://service66.mazeyard.com/dashboard">
                    We Verify Your Details.
                </Link>
                <br />
                We use your ID card and other details to verify your identity and activate your account.
            </>
        },
        {
            image: <Login sx={{ fontSize: iconSize, color: '#fd7e14' }} />,
            content: <>
                <Link href="https://www.mazeyard.com/apps">
                    Login with Service66
                </Link>
                <br />
                Login into MazeYard owned apps and other third party apps
                to prove your identity and access their services.
            </>
        }
    ]


    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100%',
            // border: '1px solid black',
            // height: `calc(100vh - 47px)`,
            // p: 3,
            pb: 5, pt: 3,
            textAlign: 'center',
        }}
    // spacing={2}
    >
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                // mt: 2,
                // border: '1px solid black',
                // bgcolor: 'primary.ultraLight',
                height: '100%',
                borderRadius: '4px',
                pt: 8,
                pb: 8,
                // pr: {
                //     xs: 2, md: 0
                // }
            }}
        >
            <Typography
                variant="h4"
                color="textPrimary"
                align="center"
                sx={{
                    // border: '1px solid black',
                    mb: {
                        xs: 3, md: 1
                    }
                }}
            >
                How It Works
            </Typography>
            <Grid
                container
                sx={{
                    backgroundColor: 'divider',
                    mt: '30px',
                    p: 5,
                    pt: 0,
                    width: {
                        xs: '95%', lg: '90%'
                    },
                    // border: '1px solid black',
                }}
                spacing={2}
            >
                {
                    items.map((eachItem, index) => {
                        return <Grid
                            item
                            xs={12}
                            md={4}
                            key={index}
                            sx={{
                                mb: {
                                    xs: 5, md: 0
                                },
                                borderBottom: {
                                    xs: '1px solid',
                                    md: 'none'
                                },
                                borderColor: 'text.secondary'
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                    // border: '1px solid black',
                                }}
                            >
                                <Paper elevation={3} sx={{ borderRadius: '50%', marginTop: '-40px' }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ width: 50, height: 50, }}
                                    >
                                        {index + 1}
                                    </Grid>
                                </Paper>
                                <IconButton
                                    size='large'
                                    sx={{
                                        width: '60px',
                                        height: '60px',
                                        fontSize: '60px',
                                        marginTop: '20px',
                                    }}
                                >
                                    {eachItem.image}
                                </IconButton>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mt: '20px',
                                        mb: {
                                            xs: '40px',
                                            md: 0
                                        }
                                    }}
                                >
                                    {eachItem.content}
                                </Typography>
                            </Grid>
                        </Grid>
                    })
                }
            </Grid>
        </Grid>
    </Grid>
}