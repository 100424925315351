import React from 'react'
import { Grid, Typography } from '@mui/material'

export default function RenderRichText(props) {
    const textContent = props.content

    const renderText = (content) => {
        return content.map((eachC) => {
            return <Typography variant="body1" color="textSecondary" align="left" gutterBottom >
                {eachC}
            </Typography>
        })
    }

    const renderNumberList = (content) => {
        return content.map((eachC, index) => {
            return <>
                <Typography variant="h6" color="textPrimary" gutterBottom >
                    {index + 1}. {eachC.title}
                </Typography>
                {render(eachC.content)}
            </>
        })
    }

    const renderAlphabeticList = (content) => {
        return content.map((eachItem, indexItem) => {
            return <Typography variant="body1" color="textSecondary" gutterBottom sx={{ paddingLeft: 2 }}>
                <strong>
                    {String.fromCharCode(indexItem + 97)}.
                </strong> {eachItem}
            </Typography>
        })
    }

    const render = (content) => {
        return content.map((eachC) => {
            if (eachC.type === 'text') {
                return renderText(eachC.content)
            }
            if (eachC.type === 'number-list') {
                return renderNumberList(eachC.content)
            }
            if (eachC.type === 'alphabet-list') {
                return renderAlphabeticList(eachC.content)
            }
            return <></>
        })
    }

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            marginTop: 1,
            pt: 5,
            pb: 5,
            marginBottom: 1,
        }}
        id={textContent.id}
    >
        <Grid
            container
            xs={11}
            sm={11}
            md={11}
            lg={9}
            xl={7}
        >
            <div ref={props.refProp}>
                <Typography variant="h5" color="textPrimary" gutterBottom style={{ textDecoration: 'underline' }}>
                    {textContent.title}
                </Typography>
            </div>
            <div style={{ paddingLeft: 2 }}>
                {render(textContent.content)}
            </div>
        </Grid>
    </Grid>
}