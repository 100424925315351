export const TermsAndConditions = {
    title: 'Terms and Conditions',
    id: 'terms-and-conditions',
    content: [
        {
            type: 'text',
            content: [
                `This agreement constitutes a binding legal contract between MazeYard 
                (mazeyard.com), (sometimes referred to as “we”, “us”, “our”, “the website”, or “the platform”) and you, 
                with respect to your use of the platform,
                applications and communications that are hosted on mazeyard.com and its subdomains.`,
                `We reserve our right to change these Terms and Conditions in the future.
                Except as explicitly stated otherwise, your continued use of the platform 
                will constitute deemed acceptance of our updated Terms and Conditions. `
            ]
        },
        {
            type: 'number-list',
            content: [
                {
                    title: 'Platform',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `Platform hereby refers to the websites, services and applications available on the mazeyard.com and all of its subdomains.`,
                                `We may from time to time, at our sole and absolute discretion and without notice to you, update the platform (or any part of it). We will not be liable to you or any third party for any modification, variation, interruption, suspension, or discontinuation of the platform.`,
                                `The platform (or any part of it) may provide links to third-party websites that are not owned, managed, or controlled by us. You expressly acknowledge and agree that we are not responsible for the content of those third-party websites. You are encouraged to read that third party’s terms and conditions, privacy policy, and any other document that governs your relationship with that third party.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Account',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `To access majority of features on this website and all of its subdomains, you are required to create an account with us in the form provided by us.`,
                                `You agree that by registering an account with us that: (i) you are at least 16 years in age; (ii) all information you provide to us during the registration process is true and accurate to the best of your belief; (iii) and you have the capacity to enter into contractual arrangements.`,
                                `You agree that you are solely responsible for your account and all activities conducted on your account. You must keep your password and any other login information private and secure. Your account is registered to you, and you cannot assign, transfer or otherwise dispose of your interest in your account.`,
                                `We may, from time to time, provide rules that govern your activities whilst using your account. You expressly acknowledge and agree that you will abide by these Rules. Should you be in breach of the Account Rules, we may (at our absolute and sole discretion) restrict, prohibit, suspend or terminate your account. Should your account be terminated in accordance with this clause, we are not liable to you, or any third party, for any loss or damage suffered.`,
                                `You can terminate your account, for any reason, by notifying us. You agree, however, that by terminating your account, your experience on the platform will be limited to only publically available features. We are not liable to you, or any third party, for any loss or damage suffered because of this.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Communications',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `By using the platform and providing your e-mail address, you agree to receive marketing or promotional materials, and other information we may send.`
                            ]
                        }
                    ]
                },
                {
                    title: 'User Submitted Content',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `The platform allows you to publish, share, store and otherwise make available certain information, text, graphics, videos, or other content.`,
                                `We cannot guarantee the accuracy, integrity, or quality of Content posted by users of the Site.`,
                                `We are not liable for any statements, representations, or Content provided by our users. Any opinions, advice, or recommendations expressed therein are those of the users providing such Content and not those of MazeYard.`,
                                `You represent and warrant that you own or otherwise control the Content you post on this platform and that the sharing of your Content on or through this platform does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity.`,
                                `We take no responsibility and assume no liability for Content you or any third party posts on or through the platform. However, by posting Content you grant us a perpetual, worldwide, irrevocable, unrestrictive, non-exclusive royalty-free license to use any Content in any manner whatsoever without compensation or attribution to you.`,
                            ]
                        }
                    ]
                },
                {
                    title: 'Prohibited Uses',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `Unless explicitly agreed by us, you acknowledge and agree that you may not use this platform for the following purposes: `
                            ]
                        },
                        {
                            type: 'alphabet-list',
                            content: [
                                `use the platform in any way that violates any applicable local, national or international law or regulation`,
                                `impersonate or attempt to impersonate any person or entity`,
                                `engage in any conduct that restricts or inhibits anyone’s use or enjoyment of this platform`,
                                `monitor or copy any of the material on this platform for any unauthorized purpose without our prior written consent`,
                                `attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the platform`,
                                `use the Site in any manner that could disable, overburden, damage, or impair the platform`,
                                `attempt to interfere with the proper working of the platform in any way whatsoever`,
                            ]
                        }
                    ]
                },
                {
                    title: 'Specifications',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `We may, from time to time, set certain minimum specifications required to access our platform to ensure all users have the best possible experience. You are solely responsible to obtain, keep and maintain all equipment and other software that meets our minimum specifications to enable you to have the best possible experience of our apps.`,
                                `We may from time to time, at our sole discretion and without notice to you, make variations, modifications, alterations, or updates to any part of this platform (“Enhancements”). These Enhancements may be made to improve this platform for you or to comply with relevant legal requirements.`,
                                `We will use reasonable endeavors to notify you of any scheduled disruptions to any of our services, including those due to any Enhancements. However, regardless of whether disruption is scheduled or not, we are not liable to you or any third party for any loss or damage caused by any disruption to our services.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Copyrights and Trademarks',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `Except as expressly stated otherwise, you acknowledge and agree that the original content on this platform and the software, features, and functionality comprising the website and its subdomains are the exclusive property of www.mazeyard.com (the “Materials”) and its licensors.`,
                                `You agree you will not copy, reproduce, create derivative works from, transmit or distribute the Materials in any way without our prior written consent.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Limitation of Liability, Indemnities and Warranties',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `Except as expressly provided by law, we shall not be liable to you or any third parties for any loss, damage, expenses, or any other liability arising directly or indirectly from the performance of our services to you.  To the fullest extent permitted by law, all warranties or conditions implied by statute, at law, by trade, custom, or otherwise are excluded.`,
                                `You acknowledge and agree that you assume sole and entire responsibility for, and indemnify and hold us harmless from, any and all claims, liabilities, losses, expenses, responsibilities, and damages by reason of any claim, proceedings, action, liability, or injury arising out of or as a result of (i) your conduct in relation to these Terms and Conditions; (ii) your use of any material, advice or other results of the services provided to you; (iii) your relations with your friends, colleagues, acquaintances and/or other third parties; or (iv) any breach of these Terms and Conditions by you.`,
                            ]
                        }
                    ]
                },
                {
                    title: 'Term and Termination',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `These Terms and Conditions will remain in full force and effect while you use the platform.`,
                                `We may, without notice to you, immediately stop providing any services to you at our sole discretion for any reason whatsoever.`,
                                `Where these Terms and Conditions are terminated in accordance with these Terms and Conditions, they will terminate without prejudice to any rights either party may have had against the other prior to termination. Further, all provisions of which by their nature should survive termination shall survive termination, including, without limitation: (i) all intellectual property you have granted us under these Terms and Conditions; and (ii) your obligation to pay any fees due to us at the time of termination; and (iii) warranty disclaimers, indemnity and limitations of liability.`,
                            ]
                        }
                    ]
                },
                {
                    title: 'Assignment',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `These Terms and Conditions will bind and inure for the benefit of the parties, including their respective successors, permitted assigns, and legal representatives.`,
                                `We may, without notice to you, immediately stop providing any services to you at our sole discretion for any reason whatsoever.`,
                                `Provided your rights are not affected under these Terms and Conditions, we will be permitted to assign our interest in these Terms and Conditions without prior notice to you. However, you may not assign, transfer, novate or other dispose of your rights and obligations under these Terms and Conditions without our express written approval (which we may withhold at our sole discretion).`
                            ]
                        }
                    ]
                },
                {
                    title: 'Privacy',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `You acknowledge that you have read and understood the terms of mazeyard.com’s Privacy Policy, which can be found on this same URL below this section.`,
                                `You agree that we may use your information (including disclosure to third parties) in accordance with our Privacy Policy.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Non-Waiver',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `Our failure to exercise, or delay in exercising, our rights under these Terms and Conditions does not operate as a waiver of that right.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Exclusion of Implied Relationships',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `Nothing in these Terms and Conditions shall be deemed or construed to constitute any party a partner, agent, representative, employer or employee of another party or to create any trust or commercial partnership unless specifically otherwise provided. We do not owe you any duty of good faith under these Terms and Conditions.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Severability',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `If any part of these Terms and Conditions are held to be illegal, invalid, or unenforceable, then that part shall be deemed deleted and shall not affect the validity and enforceability of the remaining provisions of these Terms and Conditions.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Entire Agreement',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `These Terms and Conditions constitute the entire agreement between the parties with respect to our services and supersede all previous versions of the Terms and Conditions, understandings, arrangements, agreements, and communications, whether verbal or written, between the parties.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Applicable Law and Jurisdiction',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `These Terms shall be governed by the laws of India. The Courts of Mumbai shall have exclusive jurisdiction over any dispute arising under these terms.`
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}

export const PrivacyPolicy = {
    title: 'Privacy Policy',
    id: 'privacy-policy',
    content: [
        {
            type: 'text',
            content: [
                `This privacy policy sets out how mazeyard.com (“MazeYard”) collects, processes and uses your Personal Information through your use of our Services.`
            ]
        },
        {
            type: 'number-list',
            content: [
                {
                    title: 'Definitions',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `Personal Information means any identifying information about you. This includes, but is not limited to, the following: Name, Email Address, Username/Passwords, Gender, Academic Information and usage data.`,
                                `Services means all services provided by the Platform to you, including (but not limited to) this Website and all of its subdomains.`,
                                `Website means https://www.mazeyard.com that posts a link to this Privacy Policy.`,
                                `The Platform means the Website and all of its subdomains, the services and the applications available on the Website and all of its subdomains held collectively.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Personal Information Collection',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `We only collect and use Personal Information to the extent necessary to provide you with the Services. All the Personal Information provided by you will be used to create your identity record. This record can then be used by you to sign in across all the third parties using the Identity Service66 where the party will use it according to its own privacy policy. We encourage you to read it before proceeding with the sign-in.`
                            ]
                        },
                    ]
                },
                {
                    title: 'Personal Information Retention',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `We keep Personal Information for the time necessary to provide you with Services and to meet all our legal and compliance obligations. This Personal Information is retained until you ask us to get it removed or we are required by law to get it removed.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Personal Data from Third Parties',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `We do not collect any sort of Personal Information from any third party except otherwise sent to our services explicitly.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Rights',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `With respect to Personal Information we hold, you have the following rights:`
                            ]
                        },
                        {
                            type: 'alphabet-list',
                            content: [
                                `Access: You may request from us access to your data that we hold on you.`,
                                `Rectification: If the data we hold on you is inaccurate, you may request that we correct it. If the data we hold is incomplete, you may request that we complete it. We might ask for proof of accuracy for certain information.`,
                                `Erasure: Subject to certain conditions, you may request that we erase all of the data we hold on you.`,
                                `Restrictions: Subject to certain conditions, you may request that we restrict the processing of data we hold on you.`,
                                `Portability: Subject to certain conditions, you may request that we transfer all the data we hold on you to a third party (including yourself).`,
                                `Objections: Subject to certain conditions, you may object to us processing the data we hold on you.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Your Account',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `If you choose to create an online account with us, you have the right to:`
                            ]
                        },
                        {
                            type: 'alphabet-list',
                            content: [
                                `Update or delete your account at any time`,
                                `Opt-out of receiving emails and other communications from us.`
                            ]
                        },
                        {
                            type: 'text',
                            content: [
                                `You can contact us for any necessary changes you require.`
                            ]
                        },
                    ]
                },
                {
                    title: 'Public Posts',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `You may choose to engage with our Services by posting or sharing your own content (including Personal Information) on our Platform. If you choose to voluntarily share Personal Information on a publicly available forum, we cannot prevent the further use of your Personal Information.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Minors',
                    content: [
                        {
                            type: 'text',
                            content: [
                                // `We do not knowingly collect information on children under the age of 16 (“Child”).  `
                                `You should be atleast 18 years or older in age to create an account on this platform. If you are under 18 and above 15, ask your parent to do that for you.`,
                                `We strictly do not entertain anyone here with age less than 15.`,
                                `If you become aware that someone with age less than 15 has provided us with Personal Information, please contact us. We will take necessary steps to remove that information from our servers.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Third Party Links',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `The Platform contains links to other third-party websites not owned or managed by us. This privacy policy applies to this website and all of its subdomains only. If you click a link to a third-party website, the privacy policy of that website will apply. We highly recommend that you read the privacy policies of other websites as they may be different from ours.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Merger, acquisition or asset sale',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Information may be transferred.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Updates',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `We are constantly reviewing our privacy policy and procedures to ensure that they meet best practices. This policy was last updated on October 20, 2021.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Contact us',
                    content: [
                        {
                            type: 'text',
                            content: [
                                `If you have any questions or suggestions about our privacy policy or want to know more information about the Personal Information we hold, please contact us using the support option in Service66 Dashboard.`
                            ]
                        }
                    ]
                },
                {
                    title: 'Complaints',
                    content: [
                        {
                            type: 'alphabet-list',
                            content: [
                                `If you believe that we have breached our obligations to you under this privacy policy or relevant privacy laws, please contact us using the support option in Service66 Dashboard.`,
                                `If you are not satisfied with our response, you have the right to lodge a complaint at any time to your relevant authority.`
                            ]
                        }
                    ]
                },
            ]
        }
    ]
}