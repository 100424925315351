import React from 'react'
import { Grid, Typography, Link } from '@mui/material'

export default function EachApp(props) {
    const eachApp = props.eachApp

    return <Grid
        container
        xs={12}
        lg={10}
        xl={8}
        sx={{
            borderBottom: '1px solid ',
            borderColor: 'divider',
            paddingTop: 4,
            paddingBottom: 4,
        }}
    >
        <Grid
            container
            direction={props.index % 2 === 0 ? "row" : "row-reverse"}
            justifyContent="center"
            alignItems="center"
        >
            <Grid

                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={12}
                md={6}
                sx={{
                    textAlign: 'center',
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                <img
                    src={eachApp.logo}
                    alt={eachApp.title + " logo"}
                    // width='100px'
                    // height='100px'
                    style={{
                        width: '100px',
                        height: '100px',
                        marginBottom: 1,
                        boxShadow: '0 0 6px 0px #A1A1A1',
                        // boxShadowCo
                    }}
                />
                <Typography
                    variant="h6"
                    color="textPrimary"
                    align="center"
                    sx={{
                        mt: 2,
                    }}
                >
                    {eachApp.title}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                    textAlign: 'center',
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                {
                    eachApp.description.map((desc) => {
                        return <Typography variant="body1" color="textSecondary" align="center">
                            {desc}
                        </Typography>
                    })
                }
                <div style={{ width: '100%', height: '10px' }} />
                <Link href={eachApp.link} target="_blank">
                    {eachApp.linkText}
                </Link>
            </Grid>
        </Grid>
    </Grid>
}