import { Divider } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import RenderRichText from './renderRichText'
import { TermsAndConditions, PrivacyPolicy } from './termsText'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Terms() {
    React.useEffect(() => {
        document.title = 'Terms and Conditions'
    }, [])

    const query = useQuery()
    const refterms = useRef(null)
    const refprivacy = useRef(null)
    const scroll = query.get('scroll')

    useEffect(() => {
        if (scroll !== undefined) {
            if (scroll === "privacy-policy") {
                refprivacy.current.scrollIntoView()
            }
        }
    }, [scroll])

    return <>
        <RenderRichText
            refProp={refterms}
            content={TermsAndConditions}
        />
        <Divider />
        <RenderRichText
            refProp={refprivacy}
            content={PrivacyPolicy}
        />
    </>
}
