import { Button, AppBar, Toolbar, IconButton, Menu, Typography, Box, MenuItem, Grid } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import mazeyard_logo2 from '../statics/mazeyard_logo2.png'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { cloneElement, useState } from 'react'


function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    })

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    })
}

export default function Topbar() {
    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    }
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const topBarOptions = [
        {
            title: 'Home',
            clickHandler: () => navigate('/')
        },
        // {
        //     title: 'Service66',
        //     clickHandler: () => navigate('/service66')
        // },
        {
            title: 'Apps',
            clickHandler: () => navigate('/apps')
        },
        {
            title: 'Contact',
            clickHandler: () => navigate('/contact')
        }
    ]


    const signUpBtn = <Button
        onClick={() => window.location.replace('https://service66.mazeyard.com/register')}
        sx={{
            textTransform: 'none',
            margin: 0,
            ml: {
                xs: 0,
                lg: '5px',
            }
        }}
        variant="contained"
        color="primary"
    >
        Register
    </Button>

    const logoLg = <Box
        sx={{
            flexGrow: 1,
            display: { xs: 'none', lg: 'block' },
            // border: '1px solid black'
        }}
    >
        <img src={mazeyard_logo2} alt="mazeyard-logo" height='40px' />
    </Box>

    const buttonsLg = <Box
        sx={{
            // border: '1px solid black',
            display: { xs: 'none', lg: 'block' },
            mt: {
                xs: 0,
                lg: '5px',
            }
        }}
    >
        {
            topBarOptions.map(eachButton => {
                return <Button
                    key={eachButton.title}
                    onClick={eachButton.clickHandler}
                    sx={{
                        textTransform: 'none',
                        color: 'text.primary',
                        mr: '5px'
                    }}
                >
                    {eachButton.title}
                </Button>
            })
        }
        {signUpBtn}
    </Box>

    const contentLg = <Box
        sx={{
            width: {
                xs: '100%',
                lg: '90%',
            },
            display: 'flex',
            // border: '1px solid black'
        }}
    >
        {logoLg}
        {buttonsLg}
    </Box>

    const logoXs = <Box
        sx={{
            flexGrow: 1,
            display: { xs: 'block', lg: 'none' },
            // border: '1px solid black',
        }}
    >
        <img src={mazeyard_logo2} alt="mazeyard-logo" height='40px' style={{ marginTop: '5px' }} />
    </Box>

    const buttonsXs = <Menu
        id="options-menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
            display: { xs: 'block', lg: 'none' },
            p: '5px'
        }}
    >
        {
            topBarOptions.map((eachButton) => (
                <MenuItem
                    key={eachButton.title}
                    onClick={() => {
                        handleCloseNavMenu()
                        eachButton.clickHandler()
                    }}
                    sx={{
                        mt: '2px', mb: '2px',
                    }}
                >
                    <Typography textAlign="center">{eachButton.title}</Typography>
                </MenuItem>
            ))
        }
    </Menu>

    const contentXs = <Box
        sx={{
            width: '100%',
            display: { xs: 'flex', lg: 'none' }
            // border: '1px solid black'
        }}
    >
        <IconButton
            size="large"
            aria-label="options-menu-button"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{
                color: 'text.primary',
                // border: '1px solid black',
                width: '50px',
            }}
        >
            <MenuIcon />
        </IconButton>
        {logoXs}
        {buttonsXs}
    </Box>

    return <ElevationScroll>
        <AppBar
            position="fixed"
            sx={{
                bgcolor: 'background.paper',
                // border: '1px solid black',
                p: 0, m: 0,
            }}
        >
            <Toolbar sx={{ p: 0, m: 0, }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // border: '1px solid black',
                        // minHeight: '20px',
                        m: 0,
                    }}
                >
                    {contentLg}
                    {contentXs}
                </Grid>
            </Toolbar>
        </AppBar>
    </ElevationScroll >
}