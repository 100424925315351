import { Box, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";

export default function Footer() {
    const navigate = useNavigate()

    const renderFooterSection = (title, content) => {
        return <Box>
            <Typography
                variant="body1"
                sx={{
                    color: 'background.paper',
                }}
                gutterBottom
            >
                {title}
            </Typography>
            {content}
        </Box>
    }

    const quickLinksList = [
        {
            name: 'Home',
            link: '/'
        },
        // {
        //     name: 'Service66',
        //     link: '/service66'
        // },
        {
            name: 'Terms and Conditions',
            link: '/terms'
        },
        {
            name: 'Privacy Policy',
            link: '/terms?scroll=privacy-policy'
        },
    ]

    const quickLinks = renderFooterSection(
        "Quick Links",
        quickLinksList.map((item, index) => (
            <Typography
                key={index}
                variant='body2'
                sx={{
                    color: 'background.paper',
                    cursor: 'pointer'
                }}
                onClick={() => navigate(item.link)}
            >
                {item.name}
            </Typography>
        ))
    )

    const exploreLinks = [
        {
            name: "Contact",
            link: 'https://www.mazeyard.com/contact',
        },
        {
            name: "Service66 Dashboard",
            link: 'https://service66.mazeyard.com/dashboard',
        },
        {
            name: "WithMazeYard",
            link: 'https://withmazeyard.com',
        },
        {
            name: "Build WithMazeYard",
            link: 'https://build.withmazeyard.com',
        },
    ]

    const explore = renderFooterSection(
        "Explore",
        exploreLinks.map((eachLink, index) => (
            <Typography
                key={index}
                variant='body2'
                sx={{
                    color: 'background.paper',
                    cursor: 'pointer'
                }}
            >
                <Link
                    href={eachLink.link}
                    sx={{
                        color: 'inherit',
                    }}
                >
                    {eachLink.name}
                </Link>
            </Typography>
        ))
    )

    const connectLinks = [
        {
            name: "Twitter",
            link: 'https://twitter.com/mazeyard',
        },
        {
            name: "LinkedIn",
            link: 'https://www.linkedin.com/company/mazeyard',
        },
        {
            name: "Facebook",
            link: 'https://www.facebook.com/mazeyard',
        },
        {
            name: "Instagram",
            link: 'https://www.instagram.com/mazeyard',
        },
    ]

    const connect = renderFooterSection(
        "Connect",
        connectLinks.map((eachLink, index) => (
            <Typography
                key={index}
                variant='body2'
                sx={{
                    color: 'background.paper',
                    cursor: 'pointer'
                }}
            >
                <Link
                    href={eachLink.link}
                    sx={{
                        color: 'inherit',
                    }}
                >
                    {eachLink.name}
                </Link>
            </Typography>
        ))
    )

    const logo = <Box></Box>

    const footerSections = [
        logo,
        quickLinks,
        explore,
        connect,
    ]

    return <Grid
        container
        sx={{
            width: '100%',
            border: '1px solid black',
            // height: `calc(100vh - 47px)`,
            p: 3,
            pb: 10,
            bgcolor: 'primary.ultraDark'
        }}
    >
        {
            footerSections.map((section, index) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={3}
                    key={index}
                    sx={{
                        mt: {
                            xs: 3,
                            lg: 0
                        },
                        pl: index === 0 ? 0 : 3,
                    }}
                >
                    {section}
                </Grid>
            ))
        }
    </Grid>
}