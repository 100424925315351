import React from 'react'
import { Grid } from '@mui/material'
import EachApp from './eachApp'

export default function AppsList() {
    const appsList = [
        {
            title: "Identity Service66",
            description: [
                "Your Academic Identity Partner",
                "One Click Academic Status"
            ],
            logo: "https://public-cdn.mazeyard.com/xdc1/service66_logo.png",
            linkText: "Open Service66",
            link: "https://service66.mazeyard.com"
        },
        {
            title: "Tarang Community Platform",
            description: [
                "Community and Workplace",
                "Collaboration for Classroom Purposes",
            ],
            logo: "https://public-cdn.mazeyard.com/xdc1/tarang_logo.png",
            linkText: "Open Tarang",
            link: "https://tarang.mazeyard.com"
        },
        {
            title: "Pitara Cloud Storage",
            description: [
                "Access Based Cloud Storage",
                "512 MB Space to Get Started",
            ],
            logo: "https://public-cdn.mazeyard.com/xdc1/pitara_logo.png",
            linkText: "Open Pitara",
            link: "https://pitara.mazeyard.com"
        }
    ]

    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100%',
            // border: '1px solid black'
        }}
    >
        {
            appsList.map((eachApp, index) => {
                return <EachApp
                    eachApp={eachApp}
                    index={index}
                />
            })
        }
    </Grid>
}