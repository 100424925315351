import React, { useState } from 'react'
import ContactForm from './contactForm'
import SuccessBox from './successBox'
import SuggestionText from './suggestionText'

export default function Contact() {
    React.useEffect(() => {
        document.title = 'Contact Us'
    }, [])

    const [view, setView] = useState("form")

    const renderView = () => {
        if (view === "form") {
            return <>
                <SuggestionText />
                <ContactForm
                    setView={setView}
                />
            </>
        } else {
            return <SuccessBox />
        }
    }

    return renderView()
}