import { Button, Grid, Typography } from "@mui/material";
import serverImg from '../statics/undraw_server_cluster_jwwq.svg'

export default function Developers() {

    return <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
            width: '100%',
            // border: '1px solid black',
            // height: `calc(100vh - 47px)`,
            // p: 3,
            pb: 5, pt: 3,
            textAlign: 'center'
        }}
    >
        <Grid
            container
            sx={{
                // border: '1px solid black',
                width: {
                    xs: '95%', lg: '90%',
                }
            }}
        >
            <Grid item xs={12} md={4}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: { xs: '20%' }, pb: 0 }}
                >
                    <img src={serverImg} alt="pricing" width="100%" />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={8}
                sx={{
                    mt: {
                        xs: '10px', lg: '80px',
                    },
                    p: {
                        xs: 2, lg: 0,
                    }
                }}
            >
                <Typography variant="h5" align='left'>
                    Open APIs for anyone to use
                </Typography>
                <Typography variant="body1" align='left' sx={{ mt: 2 }}>
                    Our APIs are open to everyone.
                    <br />
                    Build custom academic solutions using our secure and reliable APIs.
                    Start from scratch, or integrate into your existing application.
                </Typography>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        mt: 2
                    }}
                >
                    <Button
                        variant='outlined'
                        sx={{
                            textTransform: 'none',
                            mr: 2
                        }}
                        onClick={() => {
                            window.location.href = 'https://console.build.withmazeyard.com/auth/login'
                        }}
                    >
                        Get Started
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{
                            textTransform: 'none'
                        }}
                        onClick={() => {
                            window.location.href = 'https://docs.build.withmazeyard.com'
                        }}
                    >
                        Explore Documentation
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}